











































































































































































































































































































































































































































import Vue from 'vue';
import DatePager from '@/components/molecules/DatePager.vue';
import MessageHandle from '@/components/MessageHandle.vue';
import AbsenceModal from '@/components/molecules/AbsenceModal.vue';
import AbsenceDeleteModal from '@/components/molecules/AbsenceDeleteModal.vue';

import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import { childrenModule } from '@/store/dataModules/childrenModule';
import {
  ChatCommentResponse,
  ChatResponse,
  HomeChatResponse,
  GroupChatResponse,
  NurseryAttendanceResponse,
  NurseryChildResponse,
} from 'chaild-api/lib';
import VueLoading from '@/components/pages/Loding.vue';
import { chatModule } from '@/store/dataModules/chatModule';
import LocalDataService from '@/service/LocalDataService';
import { GetChildrenResponse } from '@/model/Response';
import { Members } from 'pusher-js';
import { meModule } from '@/store/dataModules/meModule';
import VRuntimeTemplate from 'v-runtime-template';
import { getBusinessYearForJapanTz, getDayjsInstanceTz} from 'chaild-api/src/common/moment/momentUtils';
dayjs.locale(ja);
// new Vue({
//   el: '#app',
//   methods: {
//     dblClick() {
//       alert("double click")
//     }
//   }
// })

export default Vue.extend({
  directives: {},
  components: {
    VRuntimeTemplate,
    DatePager,
    MessageHandle,
    VueLoading,
    AbsenceModal,
    AbsenceDeleteModal,
  },
  data: () => {
    return {
      date: dayjs(),
      nurseryId: 0,
      dialog: false,
      inputText: '',
      childId: 0 as number,
      timeout: 2000,
      snackBarText: '',
      snackbar: false,
      fetchLoading: false,
      btnSendLoading: false,
      btnDraftLoading: false,
      btnReadyLoading: false,
      btnLoadingChat: false,
      imgLoading: false,
      commentLoading: false,
      clickCount: 0,
      btnChatId: NaN,
      inputComment: '',
      draftChatId: '',
      readyChatId: '',
      offset: true,
      actionDialog: false,
      deleteDialog: false,
      deleteLoading: false,
      absenceRegistDialog: false,
      absencedeleteDialog: false,
      tempChat: {} as ChatResponse,
      isAttachmentUrls: false,
      showingUrls: [] as Array<{ url: string; objectKey: string; }>,
      counter: 0,
      firstScroll: 0,
      currentScroll: 0,
      isLongTapAble: true,
      setTime: null as any,
      isImgDialog: false,
      notPdfImgSrc: '',
      chatType: '',
    };
  },
  computed: {
    child(): NurseryChildResponse | null {
      if (childrenModule.child) {
        return childrenModule.child;
      }
      return null;
    },
    nextChildren(): NurseryChildResponse | null {
      if (childrenModule.children) {
        const children = childrenModule.children.children.filter(
          child => !(child.quitDate && child.quitDate < this.date.format('YYYY-MM-DD'))
        );
        const index = children.findIndex(child => child.childId === this.childId);
        return children[index + 1];
      }
      return null;
    },
    prevChildren(): NurseryChildResponse | null {
      if (childrenModule.children) {
        const children = childrenModule.children.children.filter(
          child => !(child.quitDate && child.quitDate < this.date.format('YYYY-MM-DD'))
        );
        const index = children.findIndex(child => child.childId === this.childId);
        return children[index - 1];
      }
      return null;
    },
    unReadCount(): any {
      if (chatModule.chats) {
        return chatModule.chats.unReadCount;
      }
      return null;
    },
    chatPast(): ChatResponse[] | null {
      if (chatModule.chats) {
        const chats = chatModule.chats.chats;
        const result = chats.filter(chat => {
          return chat.child.childId === this.childId;
        });
        return result;
      }
      return null;
    },
    chatComment(): ChatCommentResponse | null {
      if (chatModule.chats) {
        return chatModule.chats.comment;
      }
      return null;
    },
    chatsDraft(): ChatResponse[] {
      const chatsDraft = chatModule.chatsDraft;
      if (chatsDraft) {
        chatsDraft.sort(function(a, b) {
          if (a.date > b.date) {
            return -1;
          } else {
            return 1;
          }
        });
        return chatsDraft;
      }
      return [];
    },
    chatDetail(): ChatResponse | null {
      if (this.draftChatId && this.chatsDraft.length > 0) {
        const find = this.chatsDraft.find(chat => chat.chatId === Number(this.draftChatId));
        if (find) {
          return find;
        }
      }

      return null;
    },
    isChatCreateUserMe(): Boolean {
      const queryUserId = this.$route.query.userId as string;
      const user = LocalDataService.getUser();
      if (queryUserId && user) {
        let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
        if (!nurserySchool) {
          nurserySchool = user.nurserySchools[0];
        }
        const myUserId = nurserySchool.userId.toString();
        if (myUserId === queryUserId) {
          return true;
        }
        return false;
      }
      return true;
    },
    chatsAbsence(): NurseryAttendanceResponse[] {
      const chatsAbsence = chatModule.chatsAbsence;
      if (chatsAbsence) {
        // すでにレスポンスがソート済なため
        // chatsAbsence.sort(function(a, b) {
        //   if (a.startedAt > b.startedAt) {
        //     return -1;
        //   } else {
        //     return 1;
        //   }
        // });
        return chatsAbsence;
      }
      return [];
    },
  },
  watch: {
    date: {
      handler() {
        this.init();
      },
      deep: true,
    },
    chatPast: {
      handler: async function() {
        setTimeout(this.chatsScrollToBottom, 500);
      },
      deep: true,
    },
  },
  methods: {
    changeValue(amount) {
      this.counter = this.counter + amount;
    },
    resetValue() {
      this.counter = 0;
    },
    activeModal(chat: ChatResponse) {
      if (this.firstScroll === this.currentScroll) {
        this.actionDialog = true;
        this.tempChat = chat;
      } else {
        console.log('first', this.firstScroll);
        console.log('current', this.currentScroll);
        this.currentScroll = this.firstScroll;
      }
      console.log('first', this.firstScroll);
      console.log('current', this.currentScroll);
    },
    longTap(chat: ChatResponse) {
      console.log('LONGTAP');
      this.chatType = chat.bodyType;
      this.setTime = setTimeout(() => this.activeModal(chat), 1500);

      // if (!this.actionDialog) {
      //   this.changeValue(1);
      // }
      // console.log('AAAAAAA', this.counter);
      // if (this.counter > 5) {
      //   if (this.firstScroll === this.currentScroll) {
      //     this.actionDialog = true;
      //     this.tempChat = chat;
      //     this.resetValue();
      //   } else {
      //     console.log('firstElse', this.firstScroll);
      //     console.log('currentElse', this.currentScroll);
      //     this.currentScroll = this.firstScroll;
      //     this.resetValue();
      //   }
      //   console.log('first', this.firstScroll);
      //   console.log('current', this.currentScroll);
      // }
    },
    cancelLongTap() {
      console.log('CancelLongTap');
      clearTimeout(this.setTime);
    },
    isLink(text: string) {
      const urlArr = text.match(/(https?:\/\/[^\s]*)/g);
      if (urlArr && urlArr.length) {
        return true;
      }
      return false;
    },
    autoLink(text: string) {
      // return text.replace(/(https?:\/\/[^\s]*)/g, "<a class='highlights' href='$1'>$1</a>");
      const urlArr = text.match(/(https?:\/\/[^\s]*)/g);
      console.log('url', urlArr);
      if (urlArr && urlArr.length) {
        const replaced = text.replace(
          /(https?:\/\/[^\s]*)/g,
          `<a class='highlights' @click='urlClicked("$1")'>$1</a>`
        );
        console.log('replaced', replaced);
        return `
          <p>${replaced}</p>
        `;
      } else {
        return `
          <p>${text}</p>
        `;
      }
    },
    urlClicked(url: string) {
      console.log('urlClicked', url);
      const ua = navigator.userAgent;
      const isIOS =
        ua.indexOf('iPhone') >= 0 ||
        ua.indexOf('iPad') >= 0 ||
        Number(navigator.userAgent.indexOf('iPod')) >= 0;
      if (isIOS) {
        if ((window as any).webkit.messageHandlers) {
          (window as any).webkit.messageHandlers.openUrl.postMessage(`${url}`);
        }
      } else {
        window.open(url);
      }
    },
    async onClickAbsencedeleteDialog() {
      this.absencedeleteDialog = true;
      await chatModule.getChatsAbsence(this.childId);
    },
    onClicknextChilid() {
      this.$router.push(`/communication/chat/${this.nextChildren?.childId}`);
      location.reload();
    },
    onClickprevChilid() {
      this.$router.push(`/communication/chat/${this.prevChildren?.childId}`);
      location.reload();
    },
    tap() {
      const selectedElement: Element = document.getElementsByClassName('chat-area')[0];
      this.currentScroll = selectedElement.scrollTop;
      if (this.counter === 0) {
        this.firstScroll = selectedElement.scrollTop;
      } else {
        this.counter = 0;
      }
      console.log('scroll', this.firstScroll, this.currentScroll);
    },
    showLongText(body: string) {
      let replace = body.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
      const length = replace.length;
      if (length > 150) {
        replace = replace.slice(0, 150) + '...';
        return replace;
      }
      return body;
    },
    onCopyTextCopy() {
      console.log(this.tempChat);
      navigator.clipboard.writeText(this.tempChat.body).catch(e => {
        console.error(e);
      });
      this.actionDialog = false;
      this.snackBarText = 'コピーしました';
      this.snackbar = true;
    },
    onCopyTextError() {
      this.actionDialog = false;
      this.snackBarText = 'コピー失敗しました';
      this.snackbar = true;
    },
    insertStr(str, index, insert) {
      return str.slice(0, index) + insert + str.slice(index, str.length);
    },
    formatAmzIso(dateStr: string): string {
      let formatted = dateStr;
      formatted = this.insertStr(formatted, 4, '-');
      formatted = this.insertStr(formatted, 7, '-');
      formatted = this.insertStr(formatted, 13, ':');
      formatted = this.insertStr(formatted, 16, ':');
      return formatted;
    },
    isPresignedUrlExpired(url: string): boolean {
      try {
        const expiresFromIndex = url.indexOf('?Expires=') + 9;
        const expiresEndIndex = url.indexOf('&Key-Pair-Id=');
        const expires = Number(url.substring(expiresFromIndex, expiresEndIndex));
        if (expires) {
          const Now = dayjs();
          const isExpired = Now.isAfter(dayjs.unix(expires));
          return isExpired;
        } else {
          return true;
        }
      } catch (error) {
        return true;
      }
    },
    isPdf(objectKey: string) {
      const arr = objectKey.split('.');
      const ext = arr[arr.length - 1];
      if (ext === 'pdf') {
        return true;
      }
      return false;
    },
    async openChatImg(chat: ChatResponse, index: number) {
      let img = chat.attachmentUrls[index].url;
      const isPdf = this.isPdf(chat.attachmentUrls[index].objectKey);
      if (isPdf) {
        // const isImgExpired = this.isPresignedUrlExpired(
        //   chat.attachmentUrls[index].url,
        // );
        // if (isImgExpired) {
        //   this.imgLoading = true;
        //   const objectKey = chat.attachmentUrls[index].objectKey;
        //   const imgContent = await resourceModule.postResourceDownload({
        //     objectKey,
        //   });
        //   img = imgContent.preSignedUrl;
        //   this.imgLoading = false;
        // }
        const ua = navigator.userAgent;
        const isIOS =
          ua.indexOf('iPhone') >= 0 ||
          ua.indexOf('iPad') >= 0 ||
          Number(navigator.userAgent.indexOf('iPod')) >= 0;
        if (isIOS) {
          if ((window as any).webkit.messageHandlers) {
            (window as any).webkit.messageHandlers.pdf.postMessage(`${[img]}`);
          }
        } else {
          this.isAttachmentUrls = true;
          this.showingUrls = [
            {
              url: img,
              objectKey: chat.attachmentUrls[index].objectKey,
            },
          ];
        }
      } else {
        // let img = chat.attachmentUrls[0].url;
        // const isImgExpired = this.isPresignedUrlExpired(
        //   chat.attachmentUrls[index].url,
        // );
        // if (isImgExpired) {
        //   const objectKey = chat.attachmentUrls[index].objectKey;
        //   const imgContent = await resourceModule.postResourceDownload({
        //     objectKey,
        //   });
        //   img = imgContent.preSignedUrl;
        // }
        this.notPdfImgSrc = img;
        this.isImgDialog = true;
      }
    },
    // async openChatImg(chat: ChatResponse) {
    //   let imgs = chat.attachmentUrls;
    //   const isImgExpired = this.isPresignedUrlExpired(chat.attachmentUrls[0]);
    //   if (isImgExpired) {
    //     this.imgLoading = true;
    //     const chatContent = await chatModule.fetchChatContent({
    //       chatId: chat.chatId,
    //       date: this.date.format('YYYY-MM-DD'),
    //     });
    //     imgs = chatContent.attachmentUrls;
    //     this.imgLoading = false;
    //   }
    //   const ua = navigator.userAgent;
    //   const isIOS =
    //     ua.indexOf('iPhone') >= 0 ||
    //     ua.indexOf('iPad') >= 0 ||
    //     Number(navigator.userAgent.indexOf('iPod')) >= 0;

    //   const pdfs = imgs.filter((img) => img.indexOf('.pdf?') >= 0)
    //   const jpgpngs = imgs.filter((img) => (img.indexOf('.jpg?') >= 0) || (img.indexOf('.jpeg?') >= 0) || (img.indexOf('.png?') >= 0))
      
    //   if (isIOS && pdfs.length > 0) {
    //     console.log((window as any).webkit.messageHandlers);
    //     if ((window as any).webkit.messageHandlers) {
    //       (window as any).webkit.messageHandlers.pdf.postMessage(`${pdfs}`);
    //     }
    //   } else {
    //     this.isAttachmentUrls = true;
    //     this.showingUrls = jpgpngs;
    //   }
    // },
    closeChatImg() {
      this.isAttachmentUrls = false;
      this.showingUrls = [];
    },
    createAbsence() {
      this.init();
      this.snackBarText = '欠席登録をしました';
      this.snackbar = true;
    },
    deleteAbsence() {
      this.init();
      this.snackBarText = '欠席登録を取り消しました';
      this.snackbar = true;
    },
    clickDeleteDialog(chat: ChatResponse) {
      this.actionDialog = false;
      this.deleteDialog = true;
      this.tempChat = chat;
    },
    async deleteChat() {
      try {
        this.deleteLoading = true;
        await chatModule.deleteChat({
          childId: this.tempChat.child.childId,
          chatId: this.tempChat.chatId,
        });
        await chatModule.fetchChat({
          childId: this.childId,
          date: this.date.format('YYYY-MM-DD'),
        });
        this.deleteDialog = false;
        this.snackBarText = '削除しました';
        this.snackbar = true;
      } catch {
      } finally {
        this.deleteLoading = false;
      }
    },

    async saveComment() {
      if (this.inputComment && this.child) {
        try {
          this.commentLoading = true;
          await chatModule.saveComment({
            childId: this.child.childId,
            date: this.date.format('YYYY-MM-DD'),
            comment: this.inputComment,
          });
          chatModule.fetchChat({
            childId: this.childId,
            date: this.date.format('YYYY-MM-DD'),
          });
          this.snackBarText = 'コメント保存しました';
          this.snackbar = true;
        } catch {
        } finally {
          this.commentLoading = false;
        }
      }
    },
    async dblClick(chat: ChatResponse) {
      if (chat.chatId) {
        try {
          this.btnLoadingChat = true;
          await chatModule.confirmChat({
            chatId: chat.chatId,
            childId: this.childId,
          });
          chatModule.fetchChat({
            childId: this.childId,
            date: this.date.format('YYYY-MM-DD'),
          });
        } catch {
        } finally {
          this.btnLoadingChat = false;
        }
      }
    },
    addDay() {
      this.date = this.date.add(1, 'd');
    },
    subtractDay() {
      this.date = this.date.subtract(1, 'd');
    },
    saveDraft: async function() {
      if (this.inputText) {
        try {
          this.btnDraftLoading = true;
          if (this.draftChatId) {
            const response = await chatModule.saveChatDraft({
              childId: this.childId,
              chatId: Number(this.draftChatId),
              body: this.inputText,
            });
            if (this.child) {
              this.$router.push(`/communication/chat/${this.child.childId}`);
            }
          } else {
            const response = await chatModule.saveChatDraft({
              childId: this.childId,
              date: this.date.format('YYYY-MM-DD'),
              body: this.inputText,
            });
          }
          this.snackBarText = '下書きに保存しました';
          this.snackbar = true;
        } catch {
        } finally {
          this.inputText = '';
          this.btnDraftLoading = false;
        }
      }
    },
    saveSendReady: async function() {
      if (this.inputText) {
        try {
          this.btnReadyLoading = true;
          if (this.readyChatId) {
            const response = await chatModule.saveChatReady({
              childId: this.childId,
              chatId: Number(this.readyChatId),
              body: this.inputText,
            });
            if (this.child) {
              this.$router.push(`/communication/chat/${this.child.childId}`);
            }
          } else {
            const response = await chatModule.saveChatReady({
              childId: this.childId,
              date: this.date.format('YYYY-MM-DD'),
              body: this.inputText,
            });
          }
          this.snackBarText = '送信準備BOXに保存しました';
          this.snackbar = true;
        } catch {
        } finally {
          this.inputText = '';
          this.btnReadyLoading = false;
        }
      }
    },
    sendMessage: async function() {
      if (this.inputText) {
        try {
          this.btnSendLoading = true;
          if (this.draftChatId) {
            await chatModule.saveChatDraft({
              childId: this.childId,
              chatId: Number(this.draftChatId),
              body: this.inputText,
            });
            await chatModule.sendChat({
              childId: this.childId,
              chatId: Number(this.draftChatId),
            });
            if (this.child) {
              this.$router.push(`/communication/chat/${this.child.childId}`);
            }
          } else if (this.readyChatId) {
            await chatModule.saveChatReady({
              childId: this.childId,
              chatId: Number(this.readyChatId),
              body: this.inputText,
            });
            const chatIds = [this.readyChatId];
            await chatModule.sendChatReady(chatIds);
            await chatModule.fetchChat({
              childId: this.childId,
              date: this.date.format('YYYY-MM-DD'),
            });
            if (this.child) {
              this.$router.push(`/communication/chat/${this.child.childId}`);
            }
          } else {
            const response = await chatModule.sendChat({
              childId: this.childId,
              date: this.date.format('YYYY-MM-DD'),
              body: this.inputText,
            });
          }
          this.chatsScrollToBottom();
        } catch {
        } finally {
          this.btnSendLoading = false;
        }
      }
      this.inputText = '';
    },
    toClassDiary() {
      const classId = this.child?.nurseryClasses[0].classId;
      if (classId) {
        this.$router.push(`/record/class/${classId}`);
      }
    },
    toChildDiary() {
      const childId = this.child?.childId;
      let classId = this.child?.nurseryClasses[0].classId;
      const date = this.date.format('YYYY-MM-DD');
      this.child?.nurseryClasses.forEach(function(arrayClass){
        if(arrayClass['year'] === getBusinessYearForJapanTz(getDayjsInstanceTz(date))){
          classId = arrayClass.classId;
        }
      })
      if (childId && classId) {
        this.$router.push(`/record/class/${classId}/child/${childId}`);
      }
    },
    init: async function() {
      this.fetchLoading = true;
      const user = LocalDataService.getUser();
      if (user) {
        let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
        if (!nurserySchool) {
          nurserySchool = user.nurserySchools[0];
        }
        const nurseryId = nurserySchool.nurseryId;
        this.nurseryId = nurseryId;
        const childId = this.$route.params.childId;
        this.childId = parseInt(childId, 10);
        if (childId) {
          await chatModule.fetchChat({
            childId: this.childId,
            date: this.date.format('YYYY-MM-DD'),
          });
          await Promise.all([childrenModule.getChild(this.childId)]);
          if (this.child) {
            let bussinesClass = this.child?.nurseryClasses[0].classId;
            const date = this.date.format('YYYY-MM-DD');
            this.child.nurseryClasses.forEach(function(arrayClass){
              if(arrayClass['year'] === getBusinessYearForJapanTz(getDayjsInstanceTz(date))){
                bussinesClass = arrayClass.classId;
              }
            })
            childrenModule.getChildren({
              nurseryId,
              classId: bussinesClass,
            });
          }
        }
        if (chatModule.chats && chatModule.chats.comment) {
          this.inputComment = chatModule.chats.comment.comment;
        } else {
          this.inputComment = '';
        }
        this.$nextTick(() => {
          this.fetchLoading = false;
        });
        this.chatsScrollToBottom();
      }
    },
    formatTime(createdAt: string) {
      const created = dayjs(createdAt).format('YYYY-MM-DD');
      const selectedDate = this.date.format('YYYY-MM-DD');
      if (created === selectedDate) {
        return dayjs(createdAt).format('HH:mm');
      } else {
        return dayjs(createdAt).format(`MM/DD\nHH:mm`);
      }
    },
    chatsScrollToBottom() {
      const selectedElement: Element = document.getElementsByClassName('chat-area')[0];
      if (selectedElement) {
        selectedElement.scrollTop = selectedElement.scrollHeight;
      }
    },
    clickToParentsRecord() {
      if (this.child) {
        const classId = this.child.nurseryClasses[0].classId;
        this.$router.push({
          path: `/record/parents-record/class/${classId}/child/${this.child.childId}`,
          query: { date: `${this.date.toISOString()}` },
        });
      }
    },
  },
  async created() {
    const date = this.$route.query.date as string;
    if (date) {
      const temp = dayjs(date);
      this.date = temp;
    }
    await this.init();
    const localText = LocalDataService.getChatText();
    const tempChildId = LocalDataService.getChatChildId();
    if (localText && tempChildId) {
      const localChildId = parseInt(tempChildId, 10);
      if (this.childId && this.childId === localChildId) {
        this.inputText = localText;
      }
    }
  },
  async mounted() {
    const user = LocalDataService.getUser();
    if (user) {
      let nurserySchool = user.nurserySchools.find(nursery => nursery.isDefault === true);
      if (!nurserySchool) {
        nurserySchool = user.nurserySchools[0];
      }
      const nurseryId = nurserySchool.nurseryId;

      // 下書きからの遷移の場合(個別)
      this.draftChatId = this.$route.query.draftChatId as string;
      if (this.draftChatId) {
        Promise.all([chatModule.fetchChatDraft(nurseryId)]);
        const text = this.$route.query.text as string;
        if (text) {
          this.inputText = text;
        }
      }
      // 送信準備からの遷移の場合(個別)
      this.readyChatId = this.$route.query.readyChatId as string;
      if (this.readyChatId) {
        Promise.all([chatModule.fetchChatReady(nurseryId)]);
        const text = this.$route.query.text as string;
        if (text) {
          this.inputText = text;
        }
      }
    }
  },
  beforeDestroy() {
    childrenModule.refreshChild();
    if (this.inputText) {
      LocalDataService.setChatText(this.inputText);
      LocalDataService.setChatChildId(this.childId.toString(10));
    } else {
      LocalDataService.removeChatText();
    }

    chatModule.resetChat();

    meModule.setTabIndex('message');
  },
});
